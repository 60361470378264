<template>
    <setting-layout :title="agreementText('title')">
        <v-container>
            <v-flex text-center class="mb-10">
                <h1>{{ agreementText('sub_title') }}:</h1>
            </v-flex>
            <div v-for="(section, key) in sections" :key="key">
                <div v-html="section.content" class="static-content"></div>
            </div>
            <v-row dense>
                <v-col sm="8" cols="12" v-if="!isAccept">
                    <v-text-field
                        v-model="name"
                        :rules="nameRules"
                        :label="agreementText('field.name')"
                    />
                </v-col>
                <v-col cols>
                    <gradient-button
                        v-if="!isAccept"
                        :disabled="!name.length|| isAccept"
                        block
                        :loading="false"
                        class="mx-0 my-0"
                        @click="acceptRequest"
                    >
                        {{ agreementText('btn.accept') }}
                    </gradient-button>
                </v-col>
            </v-row>
        </v-container>
    </setting-layout>
</template>

<script>
import SettingLayout from "@/layouts/app/profile/SettingLayout";
import PageSection from "@/models/PageSection";
import GradientButton from "@/components/app/button/GradientButton";
import {mapActions, mapMutations, mapState} from "vuex";

export default {
    name: "VerifyAgreementContract",
    components: {GradientButton, SettingLayout},
    data: function () {
        return {
            name: '',
            sections: [],
            nameRules: [v => !!v || 'Name is required'],
        }
    },
    computed: {
        ...mapState({
            isAccept: state => {
                return  state.user.user.data.verifyAgreementAt
            },
        }),
    },
    methods: {
        ...mapMutations(['showSnackBar']),
        ...mapActions([
            'getCurrentUserData',
        ]),
        fetchPageSections() {
            PageSection
                .where('page', 'standard-agreement-noodzly-noodzler')
                .get()
                .then(sections => this.sections = sections)
                .catch((e) => console.log(e))
        },
        agreementText(key) {
            return this.$t(`pages.user.setting.creator.agreement.${key}`)
        },
        acceptRequest() {
            axios.post('api/agreement-contract')
                .then(async () => {
                    this.showSnackBar('Success')
                    await this.getCurrentUserData()
                    await this.$router.push({name: 'user.setting.creator'})
                })
                .catch(() => this.showSnackBar('Error'))
        }
    },
    created() {
        this.fetchPageSections()
    }
}
</script>

<style scoped>

</style>
